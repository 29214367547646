import camelCase from 'lodash/camelCase'
import BaseController from '../base_controller'

export default class extends BaseController {

  static targets = [
    ...super.targets,
    'drinksDetails',
    'fatherStatusDeceasedDetails',
    'fatherStatusLivingDetails',
    'gravidityDetails',
    'motherStatusDeceasedDetails',
    'motherStatusLivingDetails',
    'siblingDetails',
    'siblingStatusDeceasedDetails',
    'siblingStatusLivingDetails',
    'smokingDetails',
    'stoppedDrinkingDetails',
    'usedToSmokeDetails'
  ]

  // ==== Actions

  // === Checkboxes

  toggleSectionNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.sectionNoneOfTheAboveTargets,
      noneValue:     this.conditionNoneValue,
      dispatchInput: true
    })
  }

  toggleSectionItemDetails({ currentTarget }) {
    const detailsSection = currentTarget.dataset.detailsSection
    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.itemDetailsTargets.find((target) => target.dataset.detailsSection === detailsSection)
    })
  }

  toggleNestedSectionItemDetails({ currentTarget }) {
    const nested = this.itemDetailsTargets.find((target) => target.dataset.detailsSection === currentTarget.value)
    if (!nested) return

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: nested
    })
  }

  toggleSingleCheckBoxDetails({ currentTarget }) {
    this.toggleNoneInverseSelection({
      input:  currentTarget,
      target: this.singleCheckBoxDetailsTarget
    })
  }

  // Smoking habits

  toggleSmokingStatusDetails({ currentTarget }) {
    [
      { value: 'smoking', target: this.smokingDetailsTarget },
      { value: 'used_to_smoke', target: this.usedToSmokeDetailsTarget }
    ].forEach((obj) => {
      if (currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  // Alcohol use

  toggleDrinkingStatusDetails({ currentTarget }) {
    [
      { value: 'stopped_drinking', target: this.stoppedDrinkingDetailsTarget },
      { value: 'drinks', target: this.drinksDetailsTarget }
    ].forEach((obj) => {
      if (currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  // Family history

  toggleFamilyConditionsSelections({ currentTarget: { checked }, params: { condition } }) {
    const targetKey = camelCase(`${condition}_target`)

    if (this[camelCase(`has_${targetKey}`)]) {
      this[targetKey].classList.toggle(this.hideClass, !checked)
    }
  }

  toggleFamilyConditionsSelectionDetails({ currentTarget: { checked }, params: { familyMember } }) {
    const targetKey = camelCase(`${familyMember}_target`)

    if (this[camelCase(`has_${targetKey}`)]) {
      this[targetKey].classList.toggle(this.hideClass, !checked)
    }
  }

  toggleMotherStatusDetails({ currentTarget }) {
    [
      { value: 'living', target: this.motherStatusLivingDetailsTarget },
      { value: 'deceased', target: this.motherStatusDeceasedDetailsTarget }
    ].forEach((obj) => {
      if (currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleFatherStatusDetails({ currentTarget }) {
    [
      { value: 'living', target: this.fatherStatusLivingDetailsTarget },
      { value: 'deceased', target: this.fatherStatusDeceasedDetailsTarget }
    ].forEach((obj) => {
      if (currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleSiblingStatusDetails({ currentTarget }) {
    [
      { value: 'living', first_input_value: 'current_age', targets: this.siblingStatusLivingDetailsTargets },
      { value: 'deceased', first_input_value: 'age_at_death', targets: this.siblingStatusDeceasedDetailsTargets }
    ].forEach((obj) => {
      const selId  = currentTarget.name.replace(/sibling_status\]/g, `sibling_status_${obj.first_input_value}]`)
      const target = obj.targets.find((_target) => _target.querySelector(`input[name="${selId}"]`))

      if (currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target
        })
      }
    })
  }

  toggleSiblingDetails({ currentTarget }) {
    if (currentTarget.value === 'patient_has_siblings') {
      this.showRadioButtonMoreDetails({
        target: this.siblingDetailsTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.siblingDetailsTarget
      })
    }
  }

  // === Nested inputs

  addNestedTextInputItem() {
    this.addNestedForm({
      list:     this.nestedTextInputTargets,
      template: this.nestedTextTemplateTarget,
      wrapper:  this.nestedTextWrapperTarget
    })
  }

  removeNestedTextInputItem({ currentTarget }) {
    this.removeNestedForm({
      button:  currentTarget,
      wrapper: this.nestedTextWrapperTarget,
      list:    this.nestedTextInputTargets
    })
  }

  // Womens health

  toggleGravidityDetails({ currentTarget }) {
    const element = currentTarget

    if (element.value > 0) {
      this.showTarget(this.gravidityDetailsTarget)
    } else {
      this.hideTarget(this.gravidityDetailsTarget)
    }
  }

}
