import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static values = {
    primaryQueue:           String,
    notificationPreference: String
  }

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Methods

  shouldNotify(listName) {
    if (!this.primaryQueueValue) return false
    if (!this.notificationPreferenceValue) return false

    const ringing = /^(.*)_ringing$/.exec(listName)
    if (!ringing) return false
    if (this.notificationPreferenceValue === 'both') return true

    return ringing[1] === this.primaryQueueValue
  }

  // ==== Private

}
