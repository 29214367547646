import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'count'
  ]

  // ==== Actions

  // ==== Getters

  get onlineDoctorsCount() {
    return parseInt(this.countTarget.textContent, 10)
  }

  // ==== Setters

  // ==== Private

}
