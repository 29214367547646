const Vo2MaxCalculator = (controller) => {
  Object.assign(controller, {
    connect() {
      this.calculateVO2Max()
    },

    calculateVO2Max() {
      if (this.hasVoPulseTarget && this.voMaxSexValue) {
        const baseline = this.voMaxSexValue === 'male' ? 111.33 : 65.81
        const scale    = this.voMaxSexValue === 'male' ? 0.42 : 0.1847
        const pulse    = parseInt(this.voPulseTarget.value, 10)
        const voMax    = baseline - (scale * pulse)

        if (pulse && voMax && this.validReadingInputs()) {
          this.voMaxResultTarget.textContent = `${Number((voMax).toFixed(2))} ml/kg/min`
          this.voMaxStatusTarget.textContent = this.cardioVascularHealth(voMax)
        } else {
          this.voMaxResultTarget.textContent = '-'
          this.voMaxStatusTarget.textContent = '-'
        }
      }
    },

    cardioVascularHealth(value) {
      for (const [key, range] of Object.entries(this.voMaxMatrixValue)) {
        if (range.startsWith('<')) {
          if (value < parseInt(range.slice(1), 10)) {
            return key
          }
        } else if (range.includes('-')) {
          const [min, max] = range.split('-').map(Number)
          if (value >= min && value <= max) {
            return key
          }
        } else if (range.startsWith('>')) {
          if (value > parseInt(range.slice(1), 10)) {
            return key
          }
        }
      }

      return '-'
    }
  })
}

export default Vo2MaxCalculator
